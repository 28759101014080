import { Button, Input } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { dots } from "./Home";
import Lines from "./Lines";

import "./styles/Contact.css";

import emailjs from "emailjs-com";

// Aos Package
import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  const { path } = useRouteMatch();

  const getIndex = (): number => {
    switch (path) {
      case "/":
        return 1;

      case "/about":
        return 2;

      case "/portfolio":
        return 3;

      case "/contact":
        return 4;
      default:
        return 1;
    }
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if ((name || email || subject) === "" || message.length < 5) {
      alert("Please try to fill the Inputs, Thanks !");
    } else {
      emailjs
        .sendForm(
          "service_sgwlg94",
          "template_fjlwbh8",
          e.currentTarget,
          "user_yYCiPHsQ0Wu3Vl4HuODXd"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        )
        .then(() => {
          setName("");
          setMessage("");
          setSubject("");
          setEmail("");
        })
        .then(() => alert(`Email Sent Successfully, Thanks ${name}`));
    }
  };

  // Email Form
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  // Aos
  useEffect(() => {
    Aos.init({
      duration: 2000, // Duration of Animation in ms
    });
  }, []);

  return (
    <div className="component contact">
      <Lines index={getIndex()} />
      <div>
        <div className="portfolio__head" data-aos="fade-down">
          <div
            style={{ backgroundImage: `url(${dots.default})` }}
            className="portfolio__title"
          >
            <h1>Get in Touch</h1>
          </div>
          <div className="portfolio__subtitle">
            <h4>Contact Me</h4>
          </div>
        </div>
        <div className="contact__content">
          <div className="contact__credentials" data-aos="fade-right">
            <div className="contact_cred">
              <h4>Phone Number & WhatsApp</h4>
              <p>
                <a rel="noreferrer" href="tel:+212644421296">
                  Call Me
                </a>
              </p>
            </div>
            <div className="contact_cred">
              <h4>Fiverr Account</h4>
              <p>
                <a
                  href="https://www.fiverr.com/zakaria_fed"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Let's Roll
                </a>
              </p>
            </div>
          </div>

          <div className="contact__form" data-aos="fade-left">
            <form onSubmit={sendEmail}>
              <h4>Send me an Email</h4>

              <Input
                className="userName"
                placeholder="Your Name"
                name="from_name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                className="email"
                placeholder="Your Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Input
                className="subject"
                placeholder="The Subject"
                name="subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />

              <textarea
                placeholder="Message ..."
                name="message"
                className="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
