import React from "react";
import "./styles/Lines.css";

const Lines: React.FC<{index: number}> = ({index}): JSX.Element => {

  return (
    <div className="lines">
      <div className="upper__line"></div>
      <div className="lines__pageNumber">
        <div className="circleIndex">0{index}</div>
      </div>
      <div className="bottom__line"></div>
    </div>
  );
};

export default Lines;
