import React, { useEffect } from "react";
import "./styles/Portfolio.css";

import { dots } from "./Home";
import Lines from "./Lines";

import { useRouteMatch } from "react-router-dom";

// Aos Package
import Aos from "aos";
import "aos/dist/aos.css";

const dots__Line = require("../images/Dots_Line.png");
const netflixImg = require("../images/Netflix.png");
const instaImg = require("../images/Instagram.png");
const blogImg = require("../images/Blog.png");

const Portfolio: React.FC = () => {
  const { path, url } = useRouteMatch();

  const getIndex = (): number => {
    switch (path) {
      case "/":
        return 1;

      case "/about":
        return 2;

      case "/portfolio":
        return 3;

      case "/contact":
        return 4;
      default:
        return 1;
    }
  };

  // Aos
  useEffect(() => {
    Aos.init({
      duration: 2000, // Duration of Animation in ms
    });
  }, []);

  return (
    <div className="portfolio component">
      <Lines index={getIndex()} />
      <div className="portfolio__content">
        <div className="portfolio__head" data-aos="fade-down">
          <div
            style={{ backgroundImage: `url(${dots.default})` }}
            className="portfolio__title"
          >
            <h1>Portfolio</h1>
          </div>
          <div className="portfolio__subtitle">
            <h4>Showcasing my Recent Works</h4>
          </div>
        </div>

        <div className="portfolio__works" data-aos="fade-up">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://netflix-app-e8284.web.app/"
          >
            <div className="work portfolio__netflix">
              <div
                className="portfolio__dots"
                style={{
                  backgroundImage: `url(${dots__Line.default})`,
                }}
              ></div>
              <div className="portfolio__number">01</div>
              <div className="portfolio__name">Natflix Clone</div>
              <div
                className="portfolio__img"
                style={{ backgroundImage: `url(${netflixImg.default})` }}
              ></div>
            </div>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://instagram-clone-61f36.web.app/"
          >
            <div className="work portfolio__insta">
              <div
                className="portfolio__dots"
                style={{
                  backgroundImage: `url(${dots__Line.default})`,
                }}
              ></div>
              <div className="portfolio__number">02</div>
              <div className="portfolio__name">Instagram Clone</div>
              <div
                className="portfolio__img"
                style={{ backgroundImage: `url(${instaImg.default})` }}
              ></div>
            </div>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blog-b3e93.web.app/"
          >
            <div className="work portfolio__blog">
              <div
                className="portfolio__dots"
                style={{
                  backgroundImage: `url(${dots__Line.default})`,
                }}
              ></div>
              <div className="portfolio__number">03</div>
              <div className="portfolio__name">Simple Blog Website</div>
              <div
                className="portfolio__img"
                style={{ backgroundImage: `url(${blogImg.default})` }}
              ></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
