import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { dots } from "./Home";
import Lines from "./Lines";

import "./styles/About.css";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import GitHubIcon from "@material-ui/icons/GitHub";

import Aos from "aos";
import "aos/dist/aos.css";

const About: React.FC = () => {
  const { path } = useRouteMatch();

  const getIndex = (): number => {
    switch (path) {
      case "/":
        return 1;

      case "/about":
        return 2;

      case "/portfolio":
        return 3;

      case "/contact":
        return 4;
      default:
        return 1;
    }
  };

  const [windowOpened, setwindowOpened] = useState(1);

  const renderWin = (): JSX.Element => {
    switch (windowOpened) {
      case 1:
        return <Briefly />;

      case 2:
        return <HowIWork />;

      case 3:
        return <Skills />;

      default:
        return <Briefly />;
    }
  };

  const Briefly = (): JSX.Element => {
    return (
      <div className="briefly" data-aos="fade-right">
        <h4>Self Taught Developer</h4>
        <p>
          I am 18 years old, bachelor student on Math sciences. I am from
          Morocco, living in a city called Sala Al Jadida, near to Rabat.
        </p>
        <p>
          After Covid-19 hit my country economy, Morocco implemented the
          lockdown, so I spent most of my time at home. Because I felt bored, I
          thought why not to try something new and get my hands full during this
          pandemic.
        </p>
        <p>
          That's the moment where I found that web development is one of the
          Skills that I can acquire and help businesses build better and fast
          websites.
        </p>
      </div>
    );
  };

  const HowIWork = (): JSX.Element => {
    return (
      <div className="howIWork" data-aos="fade-right">
        <h4>My Strategy</h4>
        <div className="strategies">
          <div className="strategy">
            <KeyboardArrowRightIcon
              style={{ color: "#fff", fontSize: "20px" }}
            />
            <h5>Make the Design for the Website, with Adobe XD.</h5>
          </div>

          <div className="strategy">
            <KeyboardArrowRightIcon
              style={{ color: "#fff", fontSize: "20px" }}
            />
            <h5>
              Build the Template website with React, JSX, CSS and Material-Ui
            </h5>
          </div>

          <div className="strategy">
            <KeyboardArrowRightIcon
              style={{ color: "#fff", fontSize: "20px" }}
            />
            <h5>
              Adapt the Website with the Main Project Idea and its
              functionalites
            </h5>
          </div>
        </div>
      </div>
    );
  };

  // Icons from Images
  const adobeXd = require("../images/Adobe Xd.png");
  const technologies = require("../images/Technologies.png");
  const redux = require("../images/Redux.png");
  const materialUi = require("../images/Material-ui.png");
  const git = require("../images/Git.png");
  const adobes = require("../images/Adobes.png");
  const php = require("../images/Php.png");
  const mysql = require("../images/Mysql.png");

  const Skills = (): JSX.Element => {
    return (
      <div className="skills" data-aos="fade-right">
        <h4>What do I know and work with ?</h4>

        <div className="skills_content">
          <div className="skill">
            <h6>Talking in terms of Design Process, I use the Program :</h6>
            <div className="skill__icons">
              <img src={adobeXd.default} alt="Adobe Xd" />
              <h6>Adobe XD</h6>
            </div>
          </div>

          <div className="skill">
            <h6>
              And for the Front-End design of the Website, I use the
              Technologies :
            </h6>
            <div className="skill__icons technologies">
              <img className="html" src={technologies.default} alt="All Technologies" />
              <img
                className="material"
                src={materialUi.default}
                alt="Material Ui"
              />
              <img className="redux" src={redux.default} alt="Redux" />
            </div>
          </div>

          <div className="skill">
            <h6>Version Control System :</h6>
            <div className="skill__icons">
              <img
                style={{ marginRight: "1rem" }}
                src={git.default}
                alt="Git"
              />
              <GitHubIcon style={{ fontSize: "2.1rem", color: "#fff" }} />
            </div>
          </div>

          <div className="skill">
            <h6>Adobe Skills :</h6>
            <div className="skill__icons">
              <img src={adobes.default} alt="" />
            </div>
          </div>

          <div className="skill">
            <h6>Other Skills :</h6>
            <div className="skill__icons">
              <img
                style={{ marginRight: "1rem" }}
                src={mysql.default}
                alt="My SQL"
              />
              <img src={php.default} alt="PHP" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Aos
  useEffect(() => {
    Aos.init({
      duration: 2000, // Duration of Animation in ms
    });
  }, []);

  return (
    <div className="component about">
      <Lines index={getIndex()} />
      <div>
        <div className="portfolio__head" data-aos="fade-down">
          <div
            style={{ backgroundImage: `url(${dots.default})` }}
            className="portfolio__title"
          >
            <h1>About Me</h1>
          </div>
          <div className="portfolio__subtitle">
            <h4>Who is Zakaria ?</h4>
          </div>
        </div>

        <div className="about__content">
          <div className="about__sections" data-aos="fade-left">
            <div className="about__section" onClick={() => setwindowOpened(1)}>
              <h6>/01</h6>
              <p>Briefly</p>
            </div>
            <div className="about__section" onClick={() => setwindowOpened(2)}>
              <h6>/02</h6>
              <p>How I Work</p>
            </div>
            <div className="about__section" onClick={() => setwindowOpened(3)}>
              <h6>/03</h6>
              <p>Skills</p>
            </div>
          </div>
          <div className="about__section__content">{renderWin()}</div>
        </div>
      </div>
    </div>
  );
};

export default About;
