import React, { useEffect, useState } from "react";

import "./App.css";

import NavBar from "./components/NavBar";
import Home from "./components/Home";

import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { ArrowDownward, GitHub, Instagram } from "@material-ui/icons";

import "./components/styles/Footer.css";

function App() {
  const [indexPage, setIndexPage] = useState<number>(1);
  const [url, setUrl] = useState<string>("/about");

  if (indexPage > 4) {
    setIndexPage(1);
  }

  return (
    <div className="app">
      <div>
        <Router>
          <NavBar />
          <main>
            <Switch>
              <Route path="/portfolio" component={Portfolio} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/" component={Home} />
            </Switch>
          </main>
          <footer>
            <div className="footer__links">
              <a
                target="__blank"
                href="https://www.linkedin.com/in/zakaria-imzilen-4711921b0/"
              >
                <LinkedInIcon />
              </a>
              <a target="__blank" href="https://github.com/zakaria-fed">
                <GitHub />
              </a>

              <a
                target="__blank"
                href="https://www.instagram.com/zakaria_imzilen"
              >
                <Instagram />
              </a>
            </div>
            <div
              onClick={() =>
                setIndexPage((prev: number) => {
                  switch (prev) {
                    case 1:
                      setUrl("/about");
                      return 2;

                    case 2:
                      setUrl("/portfolio");
                      return 3;

                    case 3:
                      setUrl("/contact");
                      return 4;

                    case 4:
                      setUrl("/");
                      return 1;

                    default:
                      setUrl("/");
                      return prev;
                  }
                })
              }
              className="footer__arrow"
            >
              <Link className="arrow_link" to={url}>
                <ArrowDownward />
              </Link>
            </div>
          </footer>
        </Router>
      </div>
    </div>
  );
}

export default App;
