import React, { useEffect } from "react";
import Lines from "./Lines";
import "./styles/Home.css";

// Aos Package
// import Aos from "aos";
import "aos/dist/aos.css";
import Aos from "aos";

export const dots = require("../images/Dots.png");
const myProfile = require("../images/Profile.png");

const Home = () => {
  // Aos
  useEffect(() => {
    Aos.init({
      duration: 2000, // Duration of Animation in ms
    });
  }, []);

  return (
    <div className="home component">
      <Lines index={1} />
      <div>
        <div
          style={{
            backgroundImage: `url(${dots.default})`,
          }}
          className="home__intro"
          data-aos="fade-up"
        >
          <h6>Introduction</h6>
        </div>
        <div className="home__main">
          <div className="home__content" data-aos="fade-up">
            <h1>Hi, I am Zakaria Imzilen</h1>
            <h4>Front-End React Devloper</h4>
            <p>
              A Self-taught developer makes web applications and websites with
              the JavaScript language. <br />I have spent more than a year now
              working, training and practicing with HTML, CSS templates and JS
              frameworks.
            </p>
          </div>
          <div
            className="home__profile"
            style={{ backgroundImage: `url(${myProfile.default})` }}
            data-aos="fade-left"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
